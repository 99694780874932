import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Icon from '@/components/atoms/Icon';
import Button from '@/components/atoms/Button';
import favoritesAPI from '@/api/favorites';
import { getCookies } from '@/services/cookies';
import { GeneralModal } from '@/components/molecules';
import Text from '@/components/atoms/Text';
import Container from '@/components/atoms/Container';
import styles from './favoriteButton.module.scss';
import pushToMicroSite from '@/utils/pushToMicroSite';
import BrandImage from '@/components/atoms/BrandImage';

const FavoriteButton = ({
  isFavorite,
  productId,
  emptyColor = 'var(--color-grey)'
}) => {
  const [isFavoriteState, setIsFavoriteState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const router = useRouter();
  const storeId = useSelector((state) => state.storeId);

  useEffect(() => {
    setIsFavoriteState(isFavorite);
  }, [isFavorite]);

  const handleButtonClick = async () => {
    const { token } = await getCookies();
    if (!token) return setShowModal(true);

    setIsFavoriteState((prev) => !prev);
    try {
      if (isFavoriteState) {
        await favoritesAPI.remove(productId);
      } else {
        await favoritesAPI.add(productId);
      }
    } catch (error) {
      setIsFavoriteState((prev) => !prev);
      // eslint-disable-next-line no-console
    }
  };

  const handleClose = () => {
    router.push(`${pushToMicroSite()}/login`);
    setShowModal(false);
  };

  return (
    <>
      <Button
        id={`favorite-${productId}`}
        className={isFavoriteState ? 'favorite-fully' : 'favorite-empty'}
        variant="icon"
        onClick={handleButtonClick}
        withOutPadding
        startIcon={
          <Icon
            type="favorite"
            color={isFavoriteState ? 'var(--color-favorite)' : 'transparent'}
            stroke={isFavoriteState ? 'var(--color-favorite)' : emptyColor}
          />
        }
      />
      {showModal && (
        <GeneralModal
          onClose={() => setShowModal(false)}
          withoutCloseIcon
        >
          <Container className={styles.modal}>
            <button
              className={styles.close}
              onClick={() => setShowModal(false)}
            >
              <div style={{ width: '15px', height: '15px' }}>
                <Icon
                  type="close"
                  color="var(--color-white-absolute)"
                />
              </div>
            </button>
            <BrandImage
              height={storeId !== 'city' ? 20 : 45}
              primary
              brand={storeId}
            />
            <div className={styles.containerText}>
              <Text
                center
                className={styles.text}
              >
                Debés iniciar sesión para poder agregar productos a favoritos.
              </Text>
            </div>
            <Button
              variant="primary"
              label="Iniciar Sesión"
              onClick={handleClose}
            />
          </Container>
        </GeneralModal>
      )}
    </>
  );
};

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  emptyColor: PropTypes.string
};

export default FavoriteButton;
